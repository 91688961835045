import { Component, Input, OnInit } from '@angular/core';
import { Player } from 'src/models/Player';

@Component({
  selector: 'player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css']
})
export class PlayerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input() player : Player;
}
