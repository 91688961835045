import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import { from } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ChatMessage } from './ChatMessage';
//import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack'

@Injectable({
  providedIn: 'root'
})
export class SignalrService {

  private hubConnection: HubConnection
  public messages: ChatMessage[] = [];
  private connectionUrl = 'http://localhost:54642/signalr';
  private apiUrl = ''
  
  constructor(private http: HttpClient) {
    if(window.location.toString().includes('localhost')){
      this.apiUrl = 'http://localhost:54642/api/chat';
    } else {
      this.apiUrl = 'http://letsplaysolo.com/api/chat';  
    }
   }

  public connect = () => {
    this.createAndStartConnection();
    this.addListeners();
  }

  public sendMessageToApi(message: string) {
    return this.http.post(this.apiUrl, this.buildChatMessage(message))
      .pipe(tap(_ => console.log("message sucessfully sent to api controller")));
  }

  public sendMessageToHub(message: string) {
    var promise = this.hubConnection.invoke("BroadcastAsync", this.buildChatMessage(message))
      .then(() => { console.log('message sent successfully to hub'); })
      .catch((err) => console.log('error while sending a message to hub: ' + err));

    return from(promise);
  }

  // private getConnection(): HubConnection {
  //   return new HubConnectionBuilder()
  //     .withUrl(this.connectionUrl)
  //     //.withHubProtocol(new MessagePackHubProtocol())
  //     //  .configureLogging(LogLevel.Trace)
  //     .build();
  // }

  private buildChatMessage(message: string): ChatMessage {
    return {
      connectionId: this.hubConnection.connectionId,
      text: message,
      dateTime: new Date()
    };
  }

  private createAndStartConnection() {

    //Create the connection
    this.hubConnection = new signalR.HubConnectionBuilder()
    .configureLogging(signalR.LogLevel.Debug)
    .withUrl(this.connectionUrl, {
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets
    })
    .build();

    //Start the connection
    this.hubConnection.start()
      .then(() => console.log('connection started'))
      .catch((err) => console.log('error while establishing signalr connection: ' + err))
  }

  private addListeners() {
    this.hubConnection.on("messageReceivedFromApi", (data: ChatMessage) => {
      console.log("message received from API Controller")
      this.messages.push(data);
    })
    this.hubConnection.on("messageReceivedFromHub", (data: ChatMessage) => {
      console.log("message received from Hub")
      this.messages.push(data);
    })
    this.hubConnection.on("newUserConnected", (msg: string) => {
      let m = new ChatMessage();
      m.text = msg;
      m.dateTime = new Date();
      this.messages.push(m);
    })
  }
}