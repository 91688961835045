import { Bid } from "./Bid";
import { Card } from "./Card";
import { Profile } from "./profile";
import { Suit } from "./Suit";

export class Player {
    constructor() {
        this.Cards = [];
    }
    Id: number;
    Profile: Profile;
    Name : string;
    Cards : Card[];
    IsBot: boolean;
    IsDealer: boolean;
    IsSittingOut: boolean;
    Score: number;
    ScoreTrail: string;
    SeatPosition: number;
    WantsAdvice: boolean;
    LastBid: Bid;
    ValidBids: Bid[];
    HandAnalysis: string[];
    RecommendedBid: Bid;
    RecommendedBidTrump: Suit;
    TricksTaken: number;
    TricksNeeded: number;   //to either make bid or set opponent
    CardPlayed: Card;
    MadeTrump: Suit;
    IsOut: boolean;
    GotTheWheel: boolean;
    Partners: number[];

}