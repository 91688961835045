  <!-- The main playing area -->
  <div class="board center">
    <div *ngIf="!userNameVerified"  class="login">
        <input type="text" class="mt-3 mb-3 mr-3" [(ngModel)]="userNameAttempt" placeholder="Enter your name" >
        <label><input type="radio" id="gameTypeComputer" name="gameType" value="computer" [(ngModel)]="gameType"> Start a game with three computer players</label>
        <label><input type="radio" id="gameTypeStartHuman" name="gameType" value="startHuman" [(ngModel)]="gameType"> Start a new game with human players</label>
        <label><input type="radio" id="gameTypeJoinHuman"  name="gameType" value="joinHuman" [(ngModel)]="gameType" [disabled]="GamesThatCanBeJoined.length === 0"> {{ joinGameMessage}}</label>
        <div *ngIf="gameType == 'joinHuman'">
          <p>Games you can join:</p>
          <div *ngFor="let game of GamesThatCanBeJoined">
            <label>
              <input type="radio" id="" name="gamesToJoin" value="game.Name" [ngModel]="gameToJoin">
            </label>
          </div>
        </div>
        <div>
          <button class="btn btn-primary" [disabled]="userNameAttempt.length==0" (click)="userNameProvided()" style="margin-top: 9px;">Let's Play!</button>
        </div>
    </div>
    <div *ngIf="userNameVerified">
      <div class="cardtable">
        <!-- <player *ngFor="let player of Game.Players" [player]=player></player> -->
        <div class="TopAndBottomPos">
          <player class="Player2" [player]=Game.Players[2]></player>
        </div>
        <div style="display: flex; justify-content: space-between">
          <player class="Player1" [player]=Game.Players[1]></player>
          <player class="Player3" [player]=Game.Players[3]></player>
        </div>
        <div class="TopAndBottomPos">
          <player class="Player0" [player]=Game.Players[0]></player>
        </div>
      </div>
    </div>
  </div>


<!-- <div class="container mt-5" style="background: rgb(229,222,226);">
  <h1>SignalR Chat DEMO</h1>
  <input type="text" class="mt-3 mb-3 mr-3" [(ngModel)]="text">
  <button class="btn btn-primary" [disabled]="text.length==0" (click)="sendMessage()">Send Message</button>
  <h4 class="mb-3">List of Messages</h4>

  <div *ngIf="signalRService.messages.length==0">
    <p>You haven't send or received messages</p>
  </div>
  <div *ngFor="let m of signalRService.messages">
    <div class="mb-2 mt-2">
      <div><strong>ConnectionID</strong> {{m.connectionId}}</div>
      <div><strong>Date</strong> {{m.dateTime | date}}</div>
      <div><strong>Message</strong> {{m.text}}</div>
      <hr>
    </div>
  </div>
</div>
 -->

