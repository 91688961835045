import { Component, OnInit } from '@angular/core';
import { Game } from 'src/models/Game';
import { Player } from 'src/models/Player';
import { Profile } from 'src/models/profile';
import { SignalrService } from './SignalrService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'chat-ui';
  text: string = "";

  constructor(public signalRService: SignalrService) {
    this.initGame();

  }

  ngOnInit(): void {
    this.signalRService.connect();
    this.LoadUserProfiles();
    this.CheckForGamesInProgress();
  }

  UserProfile: Profile;
  UserProfilesOnThisMachine: Profile[] = [];

  userName: string = '';          //used until a profile is set up
  userNameAttempt: string = '';
  userNameVerified = false;
  userGuid;                       //used until a profile is set up
  userBrowserGuid;                //used until a profile is set up

  Game : Game;

  //startup variables
  gameType: string = 'computer';
  GamesThatCanBeJoined : Game[] = [];
  gameToJoin: string = '';
  joinGameMessage : string;

  LoadUserProfiles(){
    const profiles = localStorage.getItem('solo-player-profiles');
    if(profiles){
      this.UserProfilesOnThisMachine = JSON.parse(profiles);
      this.UserProfile = this.UserProfilesOnThisMachine.find(profile => profile.DefaultUser);
      if(this.UserProfile){
        this.userNameAttempt = this.UserProfile.UserName;
      }
    } 
  }

  userNameProvided(){
    if(document.body.clientWidth < 1000){
      //go full screen on mobile
      document.body.requestFullscreen();
    }
    if(this.userNameAttempt){
      this.userName = this.userNameAttempt;
      //Check if user name matches any profiles on the system, if not, create a profile
      this.UserProfile = this.UserProfilesOnThisMachine.find(profile => profile.UserName.toLowerCase().trim() === this.userName.toLowerCase().trim());
      if(!this.UserProfile){
        this.UserProfile = new Profile();
        this.UserProfile.UserName = this.userName;
        this.UserProfile.DefaultUser = true;
        this.UserProfilesOnThisMachine.push(this.UserProfile);
        localStorage.setItem('solo-player-profiles', JSON.stringify(this.UserProfilesOnThisMachine));
      } else {
        this.userBrowserGuid = this.UserProfile
      }
      this.UserProfilesOnThisMachine.forEach(p => p.DefaultUser = false);
      this.UserProfile.DefaultUser = true;
      localStorage.setItem('solo-player-profiles', JSON.stringify(this.UserProfilesOnThisMachine));
      this.userNameVerified = true;
    }
  }

  initGame(){
    this.Game = new Game();
    let p0 : Player = new Player();
    p0.Name = 'Don';
    p0.SeatPosition = 0;
    p0.ScoreTrail = '3-6-10-19';
    this.Game.Players.push(p0);

    let p1 : Player = new Player();
    p1.Name = 'Tony';
    p1.SeatPosition = 1;
    p0.ScoreTrail = '0-';
    this.Game.Players.push(p1);

    let p2 : Player = new Player();
    p2.Name = 'Catherine';
    p2.SeatPosition = 2;
    p2.ScoreTrail = '4-12-';
    this.Game.Players.push(p2);

    let p3 : Player = new Player();
    p3.Name = 'Marge';
    p3.SeatPosition = 3;
    p3.ScoreTrail = "3-";
    this.Game.Players.push(p3);

  }

  sendMessage(): void {
    this.signalRService.sendMessageToApi(this.text + ' (from API)').subscribe({
      next: _ => this.text = '',
      error: (err) => (console.log(err))//console.error(err)
    });

    // this.signalRService.sendMessageToHub(this.text + ' (from Hub)').subscribe({
    //   next: _ => this.text = '',
    //   error: (err) => console.error(err)
    // });
  }

  CheckForGamesInProgress() {

    //todo: add API call here  
    
    if (this.GamesThatCanBeJoined.length == 0){
      this.joinGameMessage = "No human games are in progress";
    }
    else {
      this.joinGameMessage = "Join a game with human players";
    } 

  }
}